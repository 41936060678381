import React from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import Franja from "./franja"
import Meta from './meta'
import Destacado from "./Destacado"
import { siteMetadata } from '../../gatsby-config'

class Home extends React.Component {
  componentDidMount() {
    this.props.ShowNav();
    if (!this.props.HomeState || this.props.HomeState.franjas.length == 0) {
      let baseurl = siteMetadata.apiBaseURL;
      this.props.loadHome(baseurl);
    }
  }

  componentDidUpdate(prevProps) {
  }

  render() {
    const { franjas, destacado } = this.props.HomeState;
    //console.log(this.props.HomeState);
    return (
      <>
        <h1 className="d-none">Mediateca</h1>
        <Meta site={siteMetadata} />
        <div className={"h-100 w-100 d-flex Loading " + (this.props.HomeState.lastLoad == null ? "active" : null)} >
          <div className="m-auto lds-facebook"><div></div><div></div><div></div></div>
        </div>

        <div className={this.props.HomeState.lastLoad == null ? "HomeLoading" : "HomeLoaded"}>

          <Destacado medio={destacado} />

          {franjas ?
            <>
              {franjas.map((franja, i) => (
                <Franja  {...this.props} franja={franja} key={franja.IdCategoria} />
              ))}
            </>
            :
            null

          }

        </div>
      </>
    )
  }
}



Home.propTypes = {
  HomeState: PropTypes.object.isRequired,
  loadHome: PropTypes.func.isRequired,
}


const mapStateToProps = ({ home, admin }) => {
  return {
    HomeState: home,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ShowNav: () => {
      dispatch({ type: 'SHOWNAV' })
    },
    loadHome: (baseurl) => {
      dispatch({ type: `LOADHOME`, baseurl })
    },
  }
}

const ConnectedHome = connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)


export default ConnectedHome;


