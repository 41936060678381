import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import { isFirefox } from 'react-device-detect';

const Meta = ({ site, title, imagenSrc }) => {
  const [metaState, setMetaState] =
    useState({
      location: typeof window !== "undefined" && window.location,
      title
    });


  useEffect(() => {
    if (title !== undefined && metaState.title) {
      //gtag('config', { 'page_path': metaState.location.pathname, "page_title": metaState.title });
      if (isFirefox) {
        gtag('config', site.trackingId , { 'page_path': metaState.location.pathname, "page_title": metaState.title });
      }
      //console.log("metaState3", metaState);
    }
  }, [
      metaState.location, metaState.title
    ])


  useEffect(() => {
    if (siteTitle != title) {
      setMetaState(
        {
          location: typeof window !== "undefined" && window.location,
          title: title.replace(/\|.*$/, "")
        }
      );
    }
  }, [title]);


  const siteTitle = get(site, 'title')
  title = title ? `${title}` : siteTitle
  imagenSrc = imagenSrc ? imagenSrc : `${get(site, 'siteUrl')}/img/audio710.jpg`


  return (
    <Helmet
      title={title}
      meta={[
        { name: 'twitter:card', content: 'summary' },
        {
          name: 'twitter:site',
          content: `@${get(site, 'twitter')}`,
        },
        { property: 'og:title', content: title },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: get(site, 'description'),
        },
        {
          property: 'og:url',
          content: `${get(site, 'siteUrl')}/`,
        },
        {
          property: 'og:image',
          content: imagenSrc,
        },
      ]}
    />
  )
}
export default Meta
