import { graphql } from 'gatsby'
import React from 'react'
import Home from "components/Home"


class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Home  {...this.props} />
    );
  }
}

export default Index
