import React from "react"
import { connect } from "react-redux"
import { LocalStorageManager } from "../../utils/localStorageManager"
import { VOTOPREFIX, MISVOTOS } from "../../utils/constantes"

const localStorageManager = new LocalStorageManager();

class Rate extends React.Component {
  constructor(props) {
    super(props)
    this.likehandleClick = this.likehandleClick.bind(this)
    this.dislikehandleClick = this.dislikehandleClick.bind(this)
  }

  state = {
    YaVoto: false,
    MeGusta: false
  }




  componentDidMount() {
    this.updateVotoState(this);
  }


  updateVotoState(refRate) {
    try {
      const medio = refRate.props.medio;
      let misVotos = localStorageManager.getAsObject(MISVOTOS);
      let voto = misVotos[VOTOPREFIX + medio.IdMedio];

      refRate.setState({
        YaVoto: voto !== undefined,
        MeGusta: voto
      })
    } catch (e) {
      console.log(e)
    }
  }

  setNewVoto(newVotoMeGusta) {
    const medio = this.props.medio;
    const { YaVoto, MeGusta } = this.state;
    if (!YaVoto) {
      this.props.RateMedio(medio.IdMedio, newVotoMeGusta, true, this);
      if (newVotoMeGusta) {
        medio.MeGusta++;
      } else {
        medio.NoMeGusta++;
      }
    } else if (MeGusta == newVotoMeGusta) {
      ///si ya voto y es igual significa que lo quito
      this.props.RateMedio(medio.IdMedio, newVotoMeGusta, false, this);
      if (newVotoMeGusta) {
        medio.MeGusta--;
      } else {
        medio.NoMeGusta--;
      }

    } else {
      ///si ya voto y es diferente significa que quito el anterior para luego setear el contrario
      this.props.RateMedio(medio.IdMedio, !newVotoMeGusta, false, this,
        (refRate) => {
          refRate.props.RateMedio(medio.IdMedio, newVotoMeGusta, true, refRate);//agrego like
          if (newVotoMeGusta) {
            medio.MeGusta++;
            medio.NoMeGusta--;
          } else {
            medio.MeGusta--;
            medio.NoMeGusta++;
          }
        }
      ); //quito dislike
    }
  }

  likehandleClick(e) {
    e.preventDefault()
    e.stopPropagation()
    this.setNewVoto(true);
  }


  dislikehandleClick(e) {
    e.preventDefault()
    e.stopPropagation()
    this.setNewVoto(false);
  }



  render() {
    const { MeGusta, NoMeGusta } = this.props.medio
    let alreadyLikeIt = this.state.YaVoto && this.state.MeGusta;
    let alreadyDisLikeIt = this.state.YaVoto && !this.state.MeGusta;

    return (
      <div className="RatingContainer d-inline">
        <button
          className={alreadyLikeIt ? "active btn like" : "btn like"}
          onClick={this.likehandleClick}><i className="fa fa-thumbs-up"></i>{MeGusta}</button>
        <button
          className={alreadyDisLikeIt ? "active btn dislike" : "btn dislike"}
          onClick={this.dislikehandleClick}><i className="fa fa-thumbs-down"></i>{NoMeGusta}</button>
      </div>
    )
  }
}


const mapStateToProps = ({ asignaturas }) => {
  return {
    AsignaturasState: asignaturas
  }
}

const mapDispatchToProps = dispatch => {
  return {
    RateMedio: (idMedio, like, sumar, refRate, callback) => {
      dispatch({ type: 'RATEMEDIO', idMedio, like, sumar, refRate, callback })
    },
  }
}

const ConnectedRate = connect(
  mapStateToProps,
  mapDispatchToProps
)(Rate)

export default ConnectedRate

