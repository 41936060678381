import React from "react"
import Medio from "./medio"
import axios from "axios"
import { Link } from "gatsby"
import Slider from "react-slick"
import { siteMetadata } from '../../gatsby-config'


class Franja extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    franja: null
  }

  componentDidMount() {
    if (this.props.franja) {
      this.setState({
        franja: this.props.franja
      })

      if (!this.props.franja.Medios || this.props.franja.Medios.length == 0) {
        this.fetchMedios(this.props.franja.IdCategoria);
      }

    }
  }

  componentDidUpdate(prevProps) {
  }



  fetchMedios = (id) => {
    const baseurl = siteMetadata.apiBaseURL;
    let url = baseurl + "/App_HttpHandlers/MobileAppHandler.ashx?accion=search&TipoDeBusqueda=CATEGORIA&IdCategoria=" + id
    axios
      .get(url)
      .then(({ data }) => {
        if (data) {
          this.setState({
            franja: {
              ...this.state.franja,
              Medios: data.Medios
            }

          });
        }
      })
      .catch(error => {
        console.log(error);
      })
  }


  render() {
    const { franja } = this.state;

    const sliderSettings = {
      slide: ".medioContainer",
      draggable: false,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      lazyLoad: true,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },

        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };


    if (franja && franja.Medios && franja.Medios.length > 100) {
      franja.Medios = franja.Medios.slice(0, 99);
    }

    return (
      <>
        {franja && franja.Medios && franja.Medios.length > 0 ?
          <div className="franja px-4" id={"franja-" + franja.IdCategoria}>
            <Slider {...sliderSettings}>
              {franja.Medios && franja.Medios.map((medio, i) => (
                <Medio medio={medio} key={i} />
              ))}
            </Slider>
            <span className="FranjaTitle px-2 mx-3 my-2">
              <Link to={"/play/categoria/" + franja.IdCategoria}>{franja.NombreCategoria}</Link>
            </span>
          </div>
          : null}
      </>
    )
  }
}

export default Franja;


